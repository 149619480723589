<template>
  <div class="row justify-content-center h-100">
    <div class="col col-xl-12">
      <transition mode="out-in" name="slide-fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { depositResponseState } from "@/helpers/constants";

export default {
  name: "DepositPage",
  mounted() {
    if (this.$route.query.state) {
      switch (this.$route.query.state) {
        case depositResponseState.cancel:
          this.$store.dispatch("notification/add", {
            type: "danger",
            message: this.$t("deposit.recharge_of_bewallet_is_canceled"),
            is_toast: false,
          });
          break;
        case depositResponseState.success:
          this.$store.dispatch("notification/add", {
            type: "info",
            message: this.$t("deposit.success_recharge_of_bewallet"),
            is_toast: false,
          });
          break;
      }
    }
  },
};
</script>

<style scoped></style>
